import cx from 'classnames';
import React from 'react';

import { backgroundClasses } from '../../colors';
import { Lozenge } from '../../components/Decorations/Lozenge';
import { ButtonProps } from '../../components/buttons/Button';
import { ButtonGroup } from '../../components/buttons/ButtonGroup';
import { ResponsiveImage } from '../../components/images/ResponsiveImage';
import {
  BackgroundColorType,
  ModuleRadiusType,
} from '../../components/module/BackgroundOptions';
import { SpaceType } from '../../components/module/SpacingOptions';
import { Text } from '../../components/module/Text';
import { Title } from '../../components/module/Title';
import { Width } from '../../components/module/Width';
import { Wrapper } from '../../components/module/Wrapper';
import { ImageType } from '../../types';
import {
  ImageAlignType,
  ImageColorType,
  ImageOrientationType,
  TitleSizeType,
} from './TextImageOptions';

export type TextImageProps = {
  theme?: {
    module?: {
      background?: BackgroundColorType;
      space?: SpaceType;
    };
    image?: {
      align?: ImageAlignType;
      background?: ImageColorType;
      orientationMobile?: ImageOrientationType;
    };
    title?: {
      size?: TitleSizeType;
    };
    decorations?: {
      showLozenges?: boolean;
      roundedTop?: ModuleRadiusType;
      roundedBottom?: ModuleRadiusType;
    };
  };
  title?: string;
  eyebrow?: string;
  intro?: React.ReactNode;
  image?: ImageType;
  buttons?: ButtonProps[];
};

export const TextImage = ({
  theme,
  eyebrow,
  title,
  intro,
  image,
  buttons,
}: TextImageProps) => {
  const IMAGE_BACKGROUND_STYLES: Record<ImageColorType, string> = {
    white: null,
    'lilac-base': null,
    'green-base': null,
    'blue-base': null,
    'coral-base': null,
    'orange-base': null,
    'yellow-base': null,
    'brand-base': 'linear-gradient(243.9deg, #8334C2 0%, #5B2488 100%)',
  };

  return (
    <Wrapper
      theme={{
        ...theme?.module,
        rounded: {
          top: theme?.decorations?.roundedTop,
          bottom: theme?.decorations?.roundedBottom,
        },
      }}
    >
      <div
        className={cx({
          ['py-8 sm:py-10 lg:py-16 xl:py-20']: theme?.module?.background,
        })}
      >
        <Width width="inner" className="relative">
          {theme?.decorations?.showLozenges && (
            <span
              className={cx('absolute z-20', 'right-0 top-1/2 -translate-y-full')}
            >
              <Lozenge
                size="lg"
                color={
                  theme?.module?.background === 'neutral-95' ? 'white' : 'neutral-95'
                }
                rotation={11}
              />
            </span>
          )}

          <div className="grid grid-cols-1 md:grid-cols-12 items-center md:items-start lg:items-center gap-10 lg:gap-16 xl:gap-20 relative z-30">
            <div
              className={cx('order-2 flex flex-col gap-6 md:col-span-7', {
                ['-mt-3']: eyebrow,
                ['-mt-2']: !eyebrow,
              })}
            >
              {(title || eyebrow) && (
                <Title
                  size={theme?.title?.size || '3xl'}
                  color="neutral-base"
                  eyebrow={eyebrow}
                >
                  {title}
                </Title>
              )}

              {intro && (
                <Text color="neutral-25" size="lg">
                  {intro}
                </Text>
              )}

              {buttons && <ButtonGroup items={buttons} className="mt-2" />}
            </div>

            {image && (
              <div
                className={cx(
                  'md:col-span-5 relative h-full md:px-5 tablet:px-16 lg:px-5 xl:px-10 2xl:px-[72px]',
                  'xl:-translate-y-6',
                  theme?.image?.align === 'right'
                    ? 'order-1 md:order-3'
                    : 'order-1 md:order-1',
                )}
              >
                <div
                  className={cx(
                    'max-w-[70%] xs:max-w-[60%] sm:max-w-1/2 min-w-[160px] md:max-w-none relative  md:aspect-[380/540]',
                    {
                      ['aspect-[380/540]']:
                        theme?.image?.orientationMobile === 'portrait',
                      ['aspect-[540/380]']:
                        theme?.image?.orientationMobile !== 'portrait',
                    },
                  )}
                >
                  <div
                    className={cx(
                      'absolute w-full h-full z-10 overflow-hidden',
                      'rounded-lg md:rounded-3xl lg:rounded-4xl',
                      'origin-top-left -rotate-[12deg] md:origin-center md:-rotate-[8deg]',
                      'translate-y-5 translate-x-2 md:translate-x-0 md:translate-y-10',
                      backgroundClasses[theme?.image?.background || 'brand-base'],
                    )}
                    style={{
                      background:
                        IMAGE_BACKGROUND_STYLES[
                          theme?.image?.background || 'brand-base'
                        ],
                    }}
                  />
                  <ResponsiveImage
                    {...image}
                    roundSize={25}
                    layout="fill"
                    className="rounded-lg md:rounded-3xl lg:rounded-4xl overflow-hidden z-20"
                  />
                </div>

                {theme?.decorations?.showLozenges && (
                  <span
                    className={cx(
                      'absolute z-20',
                      '-translate-y-1/2',
                      'top-1/2',
                      'left-[70%] xs:left-[60%] sm:left-1/2 md:left-auto md:-right-5 tablet:right-0',
                    )}
                  >
                    <Lozenge size="md" color="action-light" rotation={2} />
                  </span>
                )}
              </div>
            )}
          </div>
        </Width>
      </div>
    </Wrapper>
  );
};

export default React.memo(TextImage);
